import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="d2financial.us.auth0.com"
      clientId="kndUEh6FMt1aGSMXL3kd8JEP5phT3Yc4"
      redirectUri={window.location.origin}
      audience="/files"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
