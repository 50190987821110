import React, { useCallback, useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useDropzone } from 'react-dropzone'
import FileBrowser from './FileBrowser.js';
import { FileshareApi } from '../../api/fileshareApi.js';

// file upload dropzone
function MyDropzone(props) {
    const { getAccessTokenSilently, user, isAuthenticated, isLoading } = useAuth0();
    // on drop upload files to server via post request
    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        // if the fileRejection array is not empty, the user uploaded more than 10 files
        if (fileRejections.length > 1) {
            alert('Please upload 10 files or less at a time');
        } else if (!isLoading && isAuthenticated) {
            props.notifyUploading();
            getAccessTokenSilently({ audience: '/files' })
                .then((token) => {
                    let api = new FileshareApi();
                    let userId = user.sub
                    return api.uploadFileList(userId, acceptedFiles, token);
                })
                .then((res) => {
                    console.log(res);
                    // notify file manager to refresh the list of files
                    // pass in a unique string trigger the useEffect api call
                    props.refresh(acceptedFiles[0].name + 'uploaded');
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 10 })
    return (
        <div id="dropzone" {...getRootProps()}>
            <div id="dropzone-border">
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>
        </div>
    )
}

// component containing dropzone, file browser, and logic for receiving a users list of files
export function FileManager() {
    const { getAccessTokenSilently, user, isAuthenticated, isLoading } = useAuth0();

    // state representing the status of calling the api for a list of files for the current user
    const [state, setState] = useState({
        error: null,
        loading: true,
        data: null
    });
    // when update changes, the api is called for the user's list of files
    const [update, setUpdate] = useState('');
    // call api for user's files
    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setState({ error: null, loading: true, data: state.data });
            getAccessTokenSilently({ audience: '/files' })
                .then((token) => {
                    let api = new FileshareApi();
                    let userId = user.sub
                    return api.getFileList(userId, token);
                })
                .then((res) => {
                    console.log(res);
                    let countId = -1;
                    // map array of file objects into row format for the datagrid to take as a prop
                    let rows = res.map((file) => {
                        countId++;
                        let fileName = file.name.split('.');
                        let fileType = fileName.pop();
                        fileName = fileName.join();
                        return { id: file.name, filename: fileName, filetype: fileType, downloadfile: file.name }
                    });
                    setState({ error: null, loading: false, data: rows });
                })
                .catch((err) => {
                    setState({ error: err, loading: false, data: null })
                    console.log(err);
                })
        }
    }, [update]); // whenever updates changes useEffect will run

    return (
        <div>
            <MyDropzone
                refresh={(name) => setUpdate(name)}
                notifyUploading={() => setState({ error: null, loading: true, data: state.data })}
            />

            <FileBrowser
                loading={state.loading}
                data={state.data}
                error={state.error}
                refresh={(name) => setUpdate(name)}
            />

        </div>
    )
}