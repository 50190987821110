import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// class Footer extends React.Component {
//     state = {
//         currentRoute: window.location.pathname
//     }

//     componentDidMount() {
//         //listens for any route changes
//         this.props.history.listen(() => {
//             this.setState({
//                 currentRoute: window.location.pathname
//             });
//         });
//     }
const Footer =() => {
    return (
        <footer>
            <p>© 2023 D2 Financial</p>
            
            <ul>
                <li>253.839.6989</li>
                <li>frontdesk@d2.financial</li>
            </ul>
        </footer>
    );
}

export default Footer;