'use client';
import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAuth0 } from '@auth0/auth0-react'
import { Link, Outlet } from 'react-router-dom';
import { ImFilesEmpty } from "react-icons/im";
import logo from '../../img/logo_light.svg'
import { FaRegFolderOpen } from "react-icons/fa";
import { PiTicketLight } from "react-icons/pi";
const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function ResponsiveAppBar() {
  const { logout, user } = useAuth0();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const signOut = (event) => {

    logout({ returnTo: process.env.PUBLIC_URL + '/' })
    setAnchorElUser(null);
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Container maxWidth={false} >
          <Toolbar disableGutters sx={{ height: "56px" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <img style={{ 'marginLeft': '2rem' }} src={logo} alt="go to homepage" />
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user.name !== null ?
                    user.name
                    : undefined}
                    src={user.image !== null ?
                      user.image
                      : undefined} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key={"Home"} component={Link} to={'/'} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Home</Typography>
                </MenuItem>
                <MenuItem key={"Logout"} onClick={signOut}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        color="background.secondary"
      >
        <DrawerHeader>
          <Box sx={{ flexGrow: 1, alignContent: 'center', textAlign: '-webkit-center' }} m='auto'>
            <Typography
            fontFamily={'monospace'}
            fontSize={23}
            letterSpacing={2}
            marginRight={'1rem'}>
              d2Financial
            </Typography>
          </Box>
          <IconButton onClick={handleDrawerClose} sx={{ position: 'fixed' }}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List >
          <ListItem key={'files'} disablePadding component={Link} to="/portal/files">
            <ListItemButton>
              <ListItemIcon>
                <FaRegFolderOpen />
              </ListItemIcon>
              <ListItemText sx={{ 'color': 'white' }} primary={'File Manager'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'forms'} disablePadding component={Link} to="/portal/forms/new-client" >
            <ListItemButton >
              <ListItemIcon >
                <ImFilesEmpty />
              </ListItemIcon>
              <ListItemText sx={{ 'color': 'white' }} primary={'Forms'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'forms'} disablePadding component={Link} to="/portal/contest-entry" >
            <ListItemButton >
              <ListItemIcon >
                <PiTicketLight />
              </ListItemIcon>
              <ListItemText sx={{ 'color': 'white' }} primary={'Contest Entry'} />
            </ListItemButton>
          </ListItem>
          {/* <ListItem key={'billing'} disablePadding component={Link} to="/portal/billing" >
            <ListItemButton >
              <ListItemIcon >
                <IoCard />
              </ListItemIcon>
              <ListItemText sx={{ 'color': 'white' }} primary={'Billing'} />
            </ListItemButton>
          </ListItem> */}
          

        </List>
      </Drawer>
      <Main open={open}>
        <Outlet />
      </Main>
    </Box>
  );
}