import React from 'react';
import {
    ExpandMoreRounded, CallRounded, EmailRounded, PlaceRounded,
    WatchLaterRounded, DescriptionRounded, LocalAtmRounded,
    DoneAllRounded,
    TrainOutlined
} from '@mui/icons-material';
import BlobEffect from "./BlobEffect.js";
import logo from "../../img/logo.svg";
import ContactForm from "./ContactForm.js"

export class HomePage extends React.Component {
    render() {
        return (
            <div>
                <div id="star-container">
                    <div id='stars'></div>
                    <div id='stars2'></div>
                    <div id='stars3'></div>
                </div>
                <Landing />
                <Services />
                <Contact />
            </div>
        );
    }
}

// homepage without the landing section
export class Info extends React.Component {
    render() {
        return (
            <div>
                <Services />
                <Contact />
            </div>
        );
    }
}

class Landing extends React.Component {
    componentDidMount() {
        BlobEffect();
    }
    componentWillUnmount() {
        var element = document.getElementById("blob");
        element.parentNode.removeChild(element);
    }
    render() {
        return (
            <div id="landing">
                <div id="animation">
                    <img src={logo} id="logo" alt="D2 Financial logo" />
                </div>
                <div id="landing-content">
                    <h2>
                        small business consulting firm that specializes in tax
                        preparation, tax resolution, and small business accounting
                </h2>
                    <a className="cta" href="https://outlook.office365.com/owa/calendar/DoubleDFinancial@doubledfinancial.com/bookings/" target="_new">
                        schedule a free consultation
                </a>
                    <a href="#services-top">
                        <ExpandMoreRounded className="see-more" contentDescription="go to services" />
                    </a>
                </div>
            </div>
        );
    }
}

class Services extends React.Component {
    render() {
        let taxPrepDesc = (
            <p>
                we provide personal and business tax services that are
                affordable, professional, and time efficient
            </p>
        );
        let accountingDesc = (
            <p>
                moderately priced full service accounting and payroll services
            </p>
        );
        let taxResDesc = (
            <p>
                highly skilled and proficient IRS and state tax agency
                engagement work tailored services suited to your specific tax
                challenges
            </p>
        );
        return (
            <div id="services">
                <a className="anchor" id="services-top"></a>
                <h1>services</h1>
                <div className="card-container">
                    <ServiceCard title="tax preparation" desc={taxPrepDesc} icon={<DescriptionRounded />} />
                    <ServiceCard title="small business accounting" desc={accountingDesc} icon={<LocalAtmRounded />} />
                    <ServiceCard title="tax resolution" desc={taxResDesc} icon={<DoneAllRounded />} />
                </div>
            </div>
            
        )
    }
}

class ServiceCard extends React.Component {
    render() {
        return (
            <div className="service-card">
                <div className="service-icon">{this.props.icon}</div>
                <h3>{this.props.title}</h3>
                {this.props.desc}
            </div>
        );
    }
}

class Contact extends React.Component {
    render() {
        return (
            <div id="contact">
                <a className="anchor" id="contact-top"></a>
                <h1>contact</h1>
                <div className="contact-content">
                    <ContactForm />
                    <div className="vertical-divider"></div>
                    <ol>
                        <li>
                            <CallRounded style={{ fontSize: 35 }} />
                            <div className="vertical-divider"></div>
                            <p>253.839.6989</p>
                        </li>
                        <li>
                            <EmailRounded style={{ fontSize: 35 }} />
                            <div className="vertical-divider"></div>
                            <p>frontdesk@d2.financial</p>
                        </li>
                        <li>
                            <PlaceRounded style={{ fontSize: 35 }} />
                            <div className="vertical-divider"></div>
                            <p>
                                D2 Financial<br />
                                1836 S Commons<br />
                                Federal Way, WA 98003
                            </p>
                        </li>
                        <li>
                            <WatchLaterRounded style={{ fontSize: 35 }} />
                            <div className="vertical-divider"></div>
                            <div>
                                <p>
                                    Monday - Saturday:<br />
                                    10AM - 6PM
                                </p>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        )
    }
}