"use strict";
exports.__esModule = true;
var FileshareApi = /** @class */ (function () {
    function FileshareApi() {
        this.fileShareEndpointOrigin = "https://doubledfinancial-files.azurewebsites.net";
    }
    FileshareApi.prototype.uploadFileList = function (userId, files, token) {
        var formData = new FormData();
        for (var i = 0; i < files.length; ++i) {
            formData.append("file" + i, files[i]);
        }
        console.log("Trying to upload");
        return fetch(this.fileShareEndpointOrigin + "/api/file/" + userId, {
            "method": "POST",
            "body": formData,
            "headers": {
                "Authorization": "Bearer " + token
            }
        });
    };
    FileshareApi.prototype.getFileList = function (userId, token) {
        return fetch(this.fileShareEndpointOrigin + "/api/file/" + userId, {
            "headers": {
                "Authorization": "Bearer " + token
            }
        })
            .then(function (res) { return res.json(); });
    };
    FileshareApi.prototype.downloadFile = function (userId, fileName, token) {
        fetch(this.fileShareEndpointOrigin + "/api/file/" + userId + "/" + fileName, {
            "headers": {
                "Authorization": "Bearer " + token
            }
        })
            .then(function (res) { return res.blob(); })
            .then(function (response) {
            var binaryData = [];
            binaryData.push(response);
            var downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
            downloadLink.setAttribute('download', fileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
    };
    FileshareApi.prototype.deleteFile = function (userId, fileName, token) {
        return fetch(this.fileShareEndpointOrigin + "/api/file/" + userId + "/" + fileName, {
            "method": "DELETE",
            "headers": {
                "Authorization": "Bearer " + token
            }
        });
    };
    FileshareApi.prototype.searchUsers = function (type, query, token) {
        return fetch(this.fileShareEndpointOrigin + "/api/file/find?type=" + type + "&query=*" + query + "*", {
            "headers": {
                "Authorization": "Bearer " + token
            }
        })
            .then(function (res) { return res.json(); });
    };
    return FileshareApi;
}());
exports.FileshareApi = FileshareApi;
