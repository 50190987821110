import { Container, Typography } from "@mui/material"

import React from 'react'
import { Outlet, Link } from "react-router-dom";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
export default function FormParent() {
    const [alignment, setAlignment] = React.useState(null);

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment)

    };
    return (
        <Container maxWidth='xl'>
            {/* <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card >
                        <CardContent>
                            <Typography>
                                
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                <Button variant="contained" component={Link} to={"new-client"}>New Client Information</Button>

                </Grid>
                <Grid item xs={4}>
                    <Card component={Link} to={"new-client"}>
                        <CardContent>
                            <Typography>
                                New Client Information
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
             */}
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton value="new-client" aria-label="New Client Form" LinkComponent={Link} to={'new-client'}>
        <Typography fontFamily={'sans-serif'}>
            New Client Information
        </Typography>
      </ToggleButton>
      {/* <ToggleButton value="new-business" aria-label="New Business Form" LinkComponent={Link} to={'new-business'}>
        <Typography fontFamily={'sans-serif'}>
            New Business Information
        </Typography>
      </ToggleButton> */}
      {/* <ToggleButton value="form3" aria-label="New Client Form" LinkComponent={Link} to={'form3'} >
        <Typography fontFamily={'sans-serif'}>
            form3
        </Typography>
      </ToggleButton> */}
    </ToggleButtonGroup>
            <Outlet/>
        </Container>
    )
}