import React, {useEffect} from 'react';

export default function AboutPage(){
    var fbPage
    useEffect(()=> {
        window.FB.XFBML.parse();

    }, []);
        return (
            <div id="about">
                <a class="anchor" id="about-top"></a>
                <h1>about</h1>
                <div class="about-content">
                    <p>
                        Established in 1978 in the basement of an Auburn,
                        Washington family home, D2 Financial has gone through
                        several business structure and name changes. One thing has
                        been consistent: it remains the same personable and
                        reliable tax and accounting service as it was all those years
                        ago.
                    </p>
                    <div class="video-container">
                        <iframe width="" height="" src="https://www.youtube.com/embed/dq9QBslqlIg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div class="fb-page-container">
                        <h2>check us out on facebook!</h2>

                        <div
                            class="fb-page"
                            data-href="https://www.facebook.com/doubledfinancial/"
                            data-tabs="timeline" data-width="" data-height=""
                            data-small-header="false" data-adapt-container-width="true"
                            data-hide-cover="false" data-show-facepile="true">
                            <blockquote
                                cite="https://www.facebook.com/doubledfinancial/"
                                class="fb-xfbml-parse-ignore">
                                <a href="https://www.facebook.com/doubledfinancial/">
                                    Double D Financial</a>
                            </blockquote>
                        </div>

                    </div>
                </div>
            </div>
        )
}