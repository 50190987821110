import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@mui/material/CircularProgress';
import ResponsiveAppBar from './components/appbar.js';

export default function Portal() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    if (isLoading) {
        return (
        <div id='portal'>
            <h2>loading....</h2>
            <CircularProgress size="6rem" color="secondary" />
        </div> )
    }
    if (!isAuthenticated) {
        return (
            <div id='portal'><p>Please log in to access the d2 portal</p></div>
        )
    }
    return (
        isAuthenticated && (
            <div id='portal'>
                <ResponsiveAppBar session={user}/>
            </div>
        )
    );
}

