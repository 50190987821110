import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useFormik, resetForm } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { PDFDocument } from "pdf-lib";
import { useAuth0 } from "@auth0/auth0-react";
import { FileshareApi } from "../../../api/fileshareApi";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const darkTheme = createTheme(adaptV4Theme({
  palette: {
    mode: "dark",
  },
}));

const validationSchema = yup.object({
    name: yup.string("Enter your name").required("Name is required"),
    socialSecurity: yup
      .string("Enter your Social Security Number")
      .required("Social Security Number is required"),
    bday: yup.string("Enter your birthday").required("Birthday is required"),
    occupation: yup
      .string("Enter your occupation")
      .required("Occupation is required"),
    streetAdress: yup
      .string("Enter your address")
      .required("Address is required"),
    state: yup.string("Enter your state").required("State is required"),
    city: yup.string("Enter your city").required("City is required"),
    zip: yup.string("Enter your zip code").required("Zip code is required"),
    tel: yup
      .string("Enter your phone number")
      .required("Phone number is required"),
    email: yup
      .string("Enter your email")
      .required("Email is required")
});

export default function NewClientForm() {
  const { getAccessTokenSilently, user, isAuthenticated, isLoading } = useAuth0();
  const [showSpouseForm, setshowSpouseForm] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const handleOpenSuccessSnackbar = () => {
    setSubmitSuccess(true);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  }
  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
        var vals = JSON.parse(JSON.stringify(values, null, 2))
        var formPdfBytes
        var pdfDoc
        var form
        var clientName
        fetch('/forms/New Client - Personal Info (1)_fillable.pdf').then(res => res.arrayBuffer())
        .then(buffer =>  pdfDoc = PDFDocument.load(buffer)
        .then((pdf)=>{pdfDoc = pdf; form = pdfDoc.getForm()}).then(()=>{

        Object.keys(vals).forEach(function(key, index) {
            try{
                console.log(key)
                if (key == "dateOfMarriage"){
                    var field = form.getTextField('spouseDateOfMarriage')
                    field.setText(vals[key])
                }

                var field = form.getTextField(key)
                field.setText(vals[key])
              } catch (e) {
                console.error(e.message);
            }
          })
        var processed_val 
        var field
        //date
        let today = new Date().toLocaleDateString()
        form.getTextField('date').setText(today)
        //do first name for taxpayer
        clientName = vals['name'].trim()
        let nameArr = vals['name'].trim().split(" ")
        form.getTextField('lastName').setText(nameArr.pop())
        form.getTextField('name').setText(nameArr.join(" "))


        if (Object.keys(vals).includes('spouseName')){
            let nameArr = vals['spouseName'].trim().split(" ")
            form.getTextField('spouseLastName').setText(nameArr.pop())
            form.getTextField('spouseName').setText(nameArr.join(" "))
        }
        if (['city', 'state', 'zip' ].every(v => Object.keys(vals).includes(v))){
            form.getTextField('city_zip').setText(`${vals['city']}, ${vals['state']}, ${vals['zip']}`)
        }
        if (['spouseCity', 'spouseState', 'spouseZip' ].every(v => Object.keys(vals).includes(v))){
            form.getTextField('spouseCity_zip').setText(`${vals['spouseCity']}, ${vals['spouseState']}, ${vals['spouseZip']}`)
        }

        pdfDoc.save().then((bytes)=>{
            //const blob = new Blob([bytes], { type: "application/pdf;charset=utf-8" });
            var file = new File([bytes], `New Client Information - ${clientName}.pdf`, { type: "application/pdf;charset=utf-8" });
            getAccessTokenSilently({ audience: '/files' })
                .then((token) => {
                    let api = new FileshareApi();
                    let userId = user.sub
                    return api.uploadFileList(userId, [file], token);
                })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                })

        })
        handleOpenSuccessSnackbar()
        resetForm()
        }))
      }})
  if (!isAuthenticated){
    console.log('asdf')
        return (
          <Box textAlign={"center"} m={"5em"} pt={"5em"}>
          <h3 style={{ color: "#fff" }}>Please log in to complete this form</h3>
        </Box>        )
    }

  return (   
    <Box p={"3em"}>
      <Snackbar open={submitSuccess} autoHideDuration={6000} onClose={handleCloseSuccessSnackbar}>
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          New Client Form Submitted
        </Alert>
      </Snackbar>
      <form onSubmit={formik.handleSubmit}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={darkTheme}>
            <Box textAlign={"left"} mt={"2em"}>
              <h5 style={{ color: "#fff" }}>Personal Information</h5>
            </Box>
            <Box>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="socialSecurity"
                name="socialSecurity"
                label="SSN"
                value={formik.values.socialSecurity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.socialSecurity &&
                  Boolean(formik.errors.socialSecurity)
                }
                helperText={
                  formik.touched.socialSecurity && formik.errors.socialSecurity
                }
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="bday"
                name="bday"
                label="Date of Birth"
                value={formik.values.bday}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bday && Boolean(formik.errors.bday)}
                helperText={formik.touched.bday && formik.errors.bday}
                autoComplete="bday"
              />

              <TextField
                fullWidth
                id="occupation"
                name="occupation"
                label="Occupation"
                value={formik.values.occupation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.occupation && Boolean(formik.errors.occupation)
                }
                helperText={formik.touched.occupation && formik.errors.occupation}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="streetAdress"
                name="streetAdress"
                label="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                helperText={formik.touched.address1 && formik.errors.address1}
                autoComplete="on"
              />
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="state"
                    name="state"
                    label="State"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                    autoComplete="on"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    label="City"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    autoComplete="on"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="zip"
                    name="zip"
                    label="Zip"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.zip && Boolean(formik.errors.zip)}
                    helperText={formik.touched.zip && formik.errors.zip}
                    autoComplete="on"
                  />
                </Grid>
              </Grid>
              <TextField
                fullWidth
                id="tel"
                name="tel"
                label="Phone Number"
                value={formik.values.tel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tel && Boolean(formik.errors.tel)}
                helperText={formik.touched.tel && formik.errors.tel}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                autoComplete="on"
              />
              <Box textAlign={"left"} mt={"2em"}>
                <h5 style={{ color: "#fff" }}>Drivers License Information</h5>
              </Box>
              <TextField
                fullWidth
                id="driversLicense"
                name="driversLicense"
                label="Drivers License / ID"
                value={formik.values.driversLicense}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.driversLicense &&
                  Boolean(formik.errors.driversLicense)
                }
                helperText={
                  formik.touched.driversLicense && formik.errors.driversLicense
                }
                autoComplete="on"
              />
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="driversLicenseState"
                    name="driversLicenseState"
                    label="State Issue"
                    value={formik.values.driversLicenseState}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.driversLicenseState &&
                      Boolean(formik.errors.driversLicenseState)
                    }
                    helperText={
                      formik.touched.driversLicenseState &&
                      formik.errors.driversLicenseState
                    }
                    autoComplete="on"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="driversLicenseIssueDate"
                    name="driversLicenseIssueDate"
                    label="Issue Date"
                    value={formik.values.driversLicenseIssueDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.driversLicenseIssueDate &&
                      Boolean(formik.errors.driversLicenseIssueDate)
                    }
                    helperText={
                      formik.touched.driversLicenseIssueDate &&
                      formik.errors.driversLicenseIssueDate
                    }
                    autoComplete="on"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="driversLicenseExpirationDate"
                    name="driversLicenseExpirationDate"
                    label="Exp."
                    value={formik.values.driversLicenseExpirationDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.driversLicenseExpirationDate &&
                      Boolean(formik.errors.driversLicenseExpirationDate)
                    }
                    helperText={
                      formik.touched.driversLicenseExpirationDate &&
                      formik.errors.driversLicenseExpirationDate
                    }
                    autoComplete="on"
                  />
                </Grid>
              </Grid>
              <Box textAlign={"left"} mt={"2em"}>
                <h5 style={{ color: "#fff" }}>Banking Information</h5>
              </Box>
              <TextField
                fullWidth
                id="bankName"
                name="bankName"
                label="Bank Name"
                value={formik.values.bankName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                helperText={formik.touched.bankName && formik.errors.bankName}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="accountNumber"
                name="accountNumber"
                label="Account Number"
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.accountNumber &&
                  Boolean(formik.errors.accountNumber)
                }
                helperText={
                  formik.touched.accountNumber && formik.errors.accountNumber
                }
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="routingNumber"
                name="routingNumber"
                label="Routing Number"
                value={formik.values.routingNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.routingNumber &&
                  Boolean(formik.errors.routingNumber)
                }
                helperText={
                  formik.touched.routingNumber && formik.errors.routingNumber
                }
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="bankAccountType"
                name="bankAccountType"
                label="Type (Checking/Saving)"
                value={formik.values.bankAccountType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.bankAccountType &&
                  Boolean(formik.errors.bankAccountType)
                }
                helperText={
                  formik.touched.bankAccountType && formik.errors.bankAccountType
                }
                autoComplete="on"
              />
            </Box>
            <CssBaseline/>
            {showSpouseForm && (
              <Box>
                <Box textAlign={"left"} mt={"2em"}>
                  <h5 style={{ color: "#fff" }}>Spouse Information</h5>
                </Box>
                <Box >
                <TextField
                    fullWidth
                    id="dateOfMarriage"
                    name="dateOfMarriage"
                    label="Date Of Marriage"
                    value={formik.values.dateOfMarriage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.dateOfMarriage && Boolean(formik.errors.dateOfMarriage)}
                    helperText={formik.touched.dateOfMarriage && formik.errors.dateOfMarriage}
                    autoComplete="on"
                  />
                  <TextField
                    fullWidth
                    id="spouseName"
                    name="spouseName"
                    label="Name"
                    value={formik.values.spouseName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.spouseName && Boolean(formik.errors.spouseName)}
                    helperText={formik.touched.spouseName && formik.errors.spouseName}
                    autoComplete="on"
                  />

                  <TextField
                    fullWidth
                    id="spouseSocialSecurity"
                    name="spouseSocialSecurity"
                    label="SSN"
                    value={formik.values.spouseSocialSecurity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.spouseSocialSecurity &&
                      Boolean(formik.errors.spouseSocialSecurity)
                    }
                    helperText={
                      formik.touched.spouseSocialSecurity &&
                      formik.errors.spouseSocialSecurity
                    }
                    autoComplete="on"
                  />
                  <TextField
                    fullWidth
                    id="spouseBday"
                    name="spouseBday"
                    label="Date of Birth"
                    value={formik.values.spouseBday}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.spouseBday && Boolean(formik.errors.spouseBday)}
                    helperText={formik.touched.spouseBday && formik.errors.spouseBday}
                    autoComplete="bday"
                  />

                  <TextField
                    fullWidth
                    id="spouseOccupation"
                    name="spouseOccupation"
                    label="Occupation"
                    value={formik.values.spouseOccupation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.spouseOccupation &&
                      Boolean(formik.errors.spouseOccupation)
                    }
                    helperText={
                      formik.touched.spouseOccupation && formik.errors.spouseOccupation
                    }
                    autoComplete="on"
                  />
                  <TextField
                    fullWidth
                    id="spouseStreetAddress"
                    name="spouseStreetAddress"
                    label="Address"
                    value={formik.values.spouseStreetAdress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.spouseStreetAdress && Boolean(formik.errors.spouseStreetAdress)
                    }
                    helperText={formik.touched.spouseStreetAdress && formik.errors.spouseStreetAdress}
                    autoComplete="on"
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="spouseState"
                        name="spouseState"
                        label="State"
                        value={formik.values.spouseState}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.spouseState && Boolean(formik.errors.spouseState)
                        }
                        helperText={formik.touched.spouseState && formik.errors.spouseState}
                        autoComplete="on"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="spouseCity"
                        name="spouseCity"
                        label="City"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.spouseCity && Boolean(formik.errors.spouseCity)}
                        helperText={formik.touched.spouseCity && formik.errors.spouseCity}
                        autoComplete="on"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="spouseZip"
                        name="spouseZip"
                        label="Zip"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.spouseZip && Boolean(formik.errors.spouseZip)}
                        helperText={formik.touched.spouseZip && formik.errors.spouseZip}
                        autoComplete="on"
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    id="spouseTel"
                    name="spouseTel"
                    label="Phone Number"
                    value={formik.values.spouseTel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.spouseTel && Boolean(formik.errors.spouseTel)}
                    helperText={formik.touched.spouseTel && formik.errors.spouseTel}
                    autoComplete="on"
                  />
                  <TextField
                    fullWidth
                    id="spouseEmail"
                    name="spouseEmail"
                    label="Email Address"
                    value={formik.values.spouseEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.spouseEmail && Boolean(formik.errors.spouseEmail)}
                    helperText={formik.touched.spouseEmail && formik.errors.spouseEmail}
                    autoComplete="on"
                  />
                  <Box textAlign={"left"} mt={"2em"}>
                    <h5 style={{ color: "#fff" }}>Drivers License Information</h5>
                  </Box>
                  <TextField
                    fullWidth
                    id="spouseDriversLicense"
                    name="spouseDriversLicense"
                    label="Drivers License / ID"
                    value={formik.values.spouseDriversLicense}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.spouseDriversLicense &&
                      Boolean(formik.errors.spouseDriversLicense)
                    }
                    helperText={
                      formik.touched.spouseDriversLicense &&
                      formik.errors.spouseDriversLicense
                    }
                    autoComplete="on"
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="spouseDriversLicenseState"
                        name="spouseDriversLicenseState"
                        label="State Issue"
                        value={formik.values.spouseDriversLicenseState}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.spouseDriversLicenseState &&
                          Boolean(formik.errors.spouseDriversLicenseState)
                        }
                        helperText={
                          formik.touched.spouseDriversLicenseState &&
                          formik.errors.spouseDriversLicenseState
                        }
                        autoComplete="on"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="spouseDriversLicenseIssueDate"
                        name="spouseDriversLicenseIssueDate"
                        label="Issue Date"
                        value={formik.values.spouseDriversLicenseIssueDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.spouseDriversLicenseIssueDate &&
                          Boolean(formik.errors.spouseDriversLicenseIssueDate)
                        }
                        helperText={
                          formik.touched.spouseDriversLicenseIssueDate &&
                          formik.errors.spouseDriversLicenseIssueDate
                        }
                        autoComplete="on"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        id="spouseDriversLicenseExpirationDate"
                        name="spouseDriversLicenseExpirationDate"
                        label="Exp."
                        value={formik.values.spouseDriversLicenseExpirationDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.spouseDriversLicenseExpirationDate &&
                          Boolean(formik.errors.spouseDriversLicenseExpirationDate)
                        }
                        helperText={
                          formik.touched.spouseDriversLicenseExpirationDate &&
                          formik.errors.spouseDriversLicenseExpirationDate
                        }
                        autoComplete="on"
                      />
                    </Grid>
                  </Grid>
                  <Box textAlign={"left"} mt={"2em"}>
                    <h5 style={{ color: "#fff" }}>Banking Information</h5>
                  </Box>
                  <TextField
                    fullWidth
                    id="spouseBankName"
                    name="spouseBankName"
                    label="Bank Name"
                    value={formik.values.spouseBankName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.spouseBankName && Boolean(formik.errors.spouseBankName)
                    }
                    helperText={formik.touched.spouseBankName && formik.errors.spouseBankName}
                    autoComplete="on"
                  />
                  <TextField
                    fullWidth
                    id="spouseAccountNumber"
                    name="spouseAccountNumber"
                    label="Account Number"
                    value={formik.values.spouseAccountNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.spouseAccountNumber &&
                      Boolean(formik.errors.spouseAccountNumber)
                    }
                    helperText={
                      formik.touched.spouseAccountNumber && formik.errors.spouseAccountNumber
                    }
                    autoComplete="on"
                  />
                  <TextField
                    fullWidth
                    id="spouseRoutingNumber"
                    name="spouseRoutingNumber"
                    label="Routing Number"
                    value={formik.values.spouseRoutingNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.spouseRoutingNumber &&
                      Boolean(formik.errors.spouseRoutingNumber)
                    }
                    helperText={
                      formik.touched.spouseRoutingNumber && formik.errors.spouseRoutingNumber
                    }
                    autoComplete="on"
                  />
                  <TextField
                    fullWidth
                    id="spouseBankAccountType"
                    name="spouseBankAccountType"
                    label="Type (Checking/Saving)"
                    value={formik.values.spouseBankAccountType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.spouseBankAccountType &&
                      Boolean(formik.errors.spouseBankAccountType)
                    }
                    helperText={
                      formik.touched.spouseBankAccountType &&
                      formik.errors.spouseBankAccountType
                    }
                    autoComplete="on"
                  />
                </Box>
              </Box>
            )}
            {!showSpouseForm && (
              <Box mt={"2em"}>
                <Button
                  mt={"2em"}
                  fullWidth
                  variant="contained"
                  onClick={() => setshowSpouseForm((prev) => !prev)}
                >
                  Add spouse
                </Button>
              </Box>
            )}
            <CssBaseline/>
            <Box textAlign={"left"} mt={"2em"}>
              <h5 style={{ color: "#fff" }}>Dependent Information</h5>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Box textAlign={"center"} mt={"2em"}>
                  <h5 style={{ color: "#fff" }}>Dependent 1</h5>
                </Box>
                <TextField
                  
                  fullWidth
                  id="dependant1FirstName"
                  name="dependant1FirstName"
                  label="First Name and Middle Initial"
                  value={formik.values.dependant1FirstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant1FirstName &&
                    Boolean(formik.errors.dependant1FirstName)
                  }
                  helperText={
                    formik.touched.dependant1FirstName &&
                    formik.errors.dependant1FirstName
                  }
                />
                <TextField
                  fullWidth
                  id="dependant1LastName"
                  name="dependant1LastName"
                  label="Last Name"
                  value={formik.values.dependant1LastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant1LastName &&
                    Boolean(formik.errors.dependant1LastName)
                  }
                  helperText={
                    formik.touched.dependant1LastName &&
                    formik.errors.dependant1LastName
                  }
                />
                <TextField
                  fullWidth
                  id="dependant1DateOfBirth"
                  name="dependant1DateOfBirth"
                  label="Date of Birth"
                  value={formik.values.dependant1DateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant1DateOfBirth &&
                    Boolean(formik.errors.dependant1DateOfBirth)
                  }
                  helperText={
                    formik.touched.dependant1DateOfBirth &&
                    formik.errors.dependant1DateOfBirth
                  }
                />
                <TextField
                  fullWidth
                  id="dependant1SocialSecurityNumber"
                  name="dependant1SocialSecurityNumber"
                  label="SSN"
                  value={formik.values.dependant1SocialSecurityNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant1SocialSecurityNumber &&
                    Boolean(formik.errors.dependant1SocialSecurityNumber)
                  }
                  helperText={
                    formik.touched.dependant1SocialSecurityNumber &&
                    formik.errors.dependant1SocialSecurityNumber
                  }
                />
                <TextField
                  fullWidth
                  id="dependant1Relationship"
                  name="dependant1Relationship"
                  label="Relationship to Taxpayer"
                  value={formik.values.dependant1Relationship}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant1Relationship &&
                    Boolean(formik.errors.dependant1Relationship)
                  }
                  helperText={
                    formik.touched.dependant1Relationship &&
                    formik.errors.dependant1Relationship
                  }
                />
                <TextField
                  fullWidth
                  id="dependant1MonthsAtHome"
                  name="dependant1MonthsAtHome"
                  label="Months lived at home"
                  value={formik.values.dependant1MonthsAtHome}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant1MonthsAtHome &&
                    Boolean(formik.errors.dependant1MonthsAtHome)
                  }
                  helperText={
                    formik.touched.dependant1MonthsAtHome &&
                    formik.errors.dependant1MonthsAtHome
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Box textAlign={"center"} mt={"2em"}>
                  <h5 style={{ color: "#fff" }}>Dependent 2</h5>
                </Box>
                <TextField
                  fullWidth
                  id="dependant2FirstName"
                  name="dependant2FirstName"
                  label="First Name and Middle Initial"
                  value={formik.values.dependant2FirstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant2FirstName &&
                    Boolean(formik.errors.dependant2FirstName)
                  }
                  helperText={
                    formik.touched.dependant2FirstName &&
                    formik.errors.dependant2FirstName
                  }
                />
                <TextField
                  fullWidth
                  id="dependant2LastName"
                  name="dependant2LastName"
                  label="Last Name"
                  value={formik.values.dependant2LastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant2LastName &&
                    Boolean(formik.errors.dependant2LastName)
                  }
                  helperText={
                    formik.touched.dependant2LastName &&
                    formik.errors.dependant2LastName
                  }
                />
                <TextField
                  fullWidth
                  id="dependant2DateOfBirth"
                  name="dependant2DateOfBirth"
                  label="Date of Birth"
                  value={formik.values.dependant2DateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant2DateOfBirth &&
                    Boolean(formik.errors.dependant2DateOfBirth)
                  }
                  helperText={
                    formik.touched.dependant2DateOfBirth &&
                    formik.errors.dependant2DateOfBirth
                  }
                />
                <TextField
                  fullWidth
                  id="dependant2SocialSecurityNumber"
                  name="dependant2SocialSecurityNumber"
                  label="SSN"
                  value={formik.values.dependant2SocialSecurityNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant2SocialSecurityNumber &&
                    Boolean(formik.errors.dependant2SocialSecurityNumber)
                  }
                  helperText={
                    formik.touched.dependant2SocialSecurityNumber &&
                    formik.errors.dependant2SocialSecurityNumber
                  }
                />
                <TextField
                  fullWidth
                  id="dependant2Relationship"
                  name="dependant2Relationship"
                  label="Relationship to Taxpayer"
                  value={formik.values.dependant2Relationship}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant2Relationship &&
                    Boolean(formik.errors.dependant2Relationship)
                  }
                  helperText={
                    formik.touched.dependant2Relationship &&
                    formik.errors.dependant2Relationship
                  }
                />
                <TextField
                  fullWidth
                  id="dependant2MonthsAtHome"
                  name="dependant2MonthsAtHome"
                  label="Months lived at home"
                  value={formik.values.dependant2MonthsAtHome}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant2MonthsAtHome &&
                    Boolean(formik.errors.dependant2MonthsAtHome)
                  }
                  helperText={
                    formik.touched.dependant2MonthsAtHome &&
                    formik.errors.dependant2MonthsAtHome
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Box textAlign={"center"} mt={"2em"}>
                  <h5 style={{ color: "#fff" }}>Dependent 3</h5>
                </Box>
                <TextField
                  fullWidth
                  id="dependant3FirstName"
                  name="dependant3FirstName"
                  label="First Name and Middle Initial"
                  value={formik.values.dependant3FirstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant3FirstName &&
                    Boolean(formik.errors.dependant3FirstName)
                  }
                  helperText={
                    formik.touched.dependant3FirstName &&
                    formik.errors.dependant3FirstName
                  }
                />
                <TextField
                  fullWidth
                  id="dependant3LastName"
                  name="dependant3LastName"
                  label="Last Name"
                  value={formik.values.dependant3LastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant3LastName &&
                    Boolean(formik.errors.dependant3LastName)
                  }
                  helperText={
                    formik.touched.dependant3LastName &&
                    formik.errors.dependant3LastName
                  }
                />
                <TextField
                  fullWidth
                  id="dependant3DateOfBirth"
                  name="dependant3DateOfBirth"
                  label="Date of Birth"
                  value={formik.values.dependant3DateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant3DateOfBirth &&
                    Boolean(formik.errors.dependant3DateOfBirth)
                  }
                  helperText={
                    formik.touched.dependant3DateOfBirth &&
                    formik.errors.dependant3DateOfBirth
                  }
                />
                <TextField
                  fullWidth
                  id="dependant3SocialSecurityNumber"
                  name="dependant3SocialSecurityNumber"
                  label="SSN"
                  value={formik.values.dependant3SocialSecurityNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant3SocialSecurityNumber &&
                    Boolean(formik.errors.dependant3SocialSecurityNumber)
                  }
                  helperText={
                    formik.touched.dependant3SocialSecurityNumber &&
                    formik.errors.dependant3SocialSecurityNumber
                  }
                />
                <TextField
                  fullWidth
                  id="dependant3Relationship"
                  name="dependant3Relationship"
                  label="Relationship to Taxpayer"
                  value={formik.values.dependant3Relationship}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant3Relationship &&
                    Boolean(formik.errors.dependant3Relationship)
                  }
                  helperText={
                    formik.touched.dependant3Relationship &&
                    formik.errors.dependant3Relationship
                  }
                />
                <TextField
                  fullWidth
                  id="dependant3MonthsAtHome"
                  name="dependant3MonthsAtHome"
                  label="Months lived at home"
                  value={formik.values.dependant3MonthsAtHome}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant3MonthsAtHome &&
                    Boolean(formik.errors.dependant3MonthsAtHome)
                  }
                  helperText={
                    formik.touched.dependant3MonthsAtHome &&
                    formik.errors.dependant3MonthsAtHome
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Box textAlign={"center"} mt={"2em"}>
                  <h5 style={{ color: "#fff" }}>Dependent 4</h5>
                </Box>
                <TextField
                  fullWidth
                  id="dependant4FirstName"
                  name="dependant4FirstName"
                  label="First Name and Middle Initial"
                  value={formik.values.dependant4FirstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant4FirstName &&
                    Boolean(formik.errors.dependant4FirstName)
                  }
                  helperText={
                    formik.touched.dependant4FirstName &&
                    formik.errors.dependant4FirstName
                  }
                />
                <TextField
                  fullWidth
                  id="dependant4LastName"
                  name="dependant4LastName"
                  label="Last Name"
                  value={formik.values.dependant4LastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant4LastName &&
                    Boolean(formik.errors.dependant4LastName)
                  }
                  helperText={
                    formik.touched.dependant4LastName &&
                    formik.errors.dependant4LastName
                  }
                />
                <TextField
                  fullWidth
                  id="dependant4DateOfBirth"
                  name="dependant4DateOfBirth"
                  label="Date of Birth"
                  value={formik.values.dependant4DateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant4DateOfBirth &&
                    Boolean(formik.errors.dependant4DateOfBirth)
                  }
                  helperText={
                    formik.touched.dependant4DateOfBirth &&
                    formik.errors.dependant4DateOfBirth
                  }
                />
                <TextField
                  fullWidth
                  id="dependant4SocialSecurityNumber"
                  name="dependant4SocialSecurityNumber"
                  label="SSN"
                  value={formik.values.dependant4SocialSecurityNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant4SocialSecurityNumber &&
                    Boolean(formik.errors.dependant4SocialSecurityNumber)
                  }
                  helperText={
                    formik.touched.dependant4SocialSecurityNumber &&
                    formik.errors.dependant4SocialSecurityNumber
                  }
                />
                <TextField
                  fullWidth
                  id="dependant4Relationship"
                  name="dependant4Relationship"
                  label="Relationship to Taxpayer"
                  value={formik.values.dependant4Relationship}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant4Relationship &&
                    Boolean(formik.errors.dependant4Relationship)
                  }
                  helperText={
                    formik.touched.dependant4Relationship &&
                    formik.errors.dependant4Relationship
                  }
                />
                <TextField
                  fullWidth
                  id="dependant4MonthsAtHome"
                  name="dependant4MonthsAtHome"
                  label="Months lived at home"
                  value={formik.values.dependant4MonthsAtHome}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dependant4MonthsAtHome &&
                    Boolean(formik.errors.dependant4MonthsAtHome)
                  }
                  helperText={
                    formik.touched.dependant4MonthsAtHome &&
                    formik.errors.dependant4MonthsAtHome
                  }
                />
              </Grid>
            </Grid>
            <Box mt={"2em"}>
              <Button
                mt={"2em"}
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </ThemeProvider>
        </StyledEngineProvider>
      </form>
    </Box>
  );
}

{
  /* <Grid container spacing={2} p={'2em'}>
            <Grid item xs={4}>

            </Grid>
            <Grid item xs={4}>
                
                    Taxpayer
                
            </Grid>
            <Grid item xs={4}>
                    <p color={'#fff'} style={{color: '#fff'}} >Spouse</p>
            </Grid>

            <Grid item xs={4}>
                
                    First Name & Middle Initial
                
            </Grid>
            <Grid item xs={4}>
                    <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    autoComplete='on'
                    />
            </Grid>
            <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id="firstNameSpouse"
                        name="firstNameSpouse"
                        label="Spouse's First Name"
                        value={formik.values.firstNameSpouse}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstNameSpouse && Boolean(formik.errors.firstNameSpouse)}
                        helperText={formik.touched.firstNameSpouse && formik.errors.firstNameSpouse}
                        autoComplete='on'
                        />
            </Grid>

            <Grid item xs={4}>
                
            <p color={'#fff'} style={{color: '#fff'}} >Middle Name</p>
                
            </Grid>
            <Grid item xs={4}>
                
                    <TextField
                        fullWidth
                        id="additional-name"
                        name="additional-name"
                        label="Middle Name"
                        value={formik.values.additionalName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.additionalName && Boolean(formik.errors.additionalName)}
                        helperText={formik.touched.additionalName && formik.errors.additionalName}
                        autoComplete='on'
                        />
                
            </Grid>
            <Grid item xs={4}>
                 
                    <TextField
                        fullWidth
                        id="additional-nameSpouse"
                        name="additional-nameSpouse"
                        label="Spouse's Middle Name"
                        value={formik.values.additionalNameSpouse}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.additionalNameSpouse && Boolean(formik.errors.additionalNameSpouse)}
                        helperText={formik.touched.additionalNameSpouse && formik.errors.additionalNameSpouse}
                    />
                
            </Grid>

            <Grid item xs={4}>
                
            <p color={'#fff'} style={{color: '#fff'}} >Last Name</p>
                
            </Grid>
            <Grid item xs={4}>
                
                    <TextField
                        fullWidth
                        id="family-name"
                        name="family-name"
                        label="Last Name"
                        value={formik.values.familyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.familyName && Boolean(formik.errors.familyName)}
                        helperText={formik.touched.familyName && formik.errors.familyName}
                    />
                
            </Grid>
            <Grid item xs={4}>
                
                    <TextField
                        fullWidth
                        id="family-nameSpouse"
                        name="family-nameSpouse"
                        label="Last Name"
                        value={formik.values.familyNameSpouse}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.familyNameSpouse && Boolean(formik.errors.familyNameSpouse)}
                        helperText={formik.touched.familyNameSpouse && formik.errors.familyNameSpouse}
                    />
                
            </Grid>
        </Grid>
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
        </ThemeProvider> */
}
