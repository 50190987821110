import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { FileshareApi } from '../../api/fileshareApi';
import { DataGrid} from '@mui/x-data-grid';
import { createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import CircularProgress from '@mui/material/CircularProgress';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// sort datagrid by file name in alphabetical order
const sortModel = [
  {
    field: 'filename',
    sort: 'asc'
  },
];

function NoFilesOverlay() {
  return (
    <div className="no-files">
      <SentimentDissatisfiedRoundedIcon fontSize="inherit" />
      <p>No files associated with this account. Upload files in the box above.</p>
    </div>)
}

function DeleteButton(props) {
  if (props.numFiles < 1) {
    return <div style={{ display: "none" }}></div>
  }
  return (
    <div>
      <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} size="large"
        onClick={() => { props.handleClick() }}>
        Delete {props.numFiles} file{props.numFiles > 1 ? 's' : ''}</Button>
    </div>
  )
}

function DownloadButton(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { user, isAuthenticated, isLoading } = useAuth0();

  function downloadFile(fileName) {
    getAccessTokenSilently({ audience: '/files' })
      .then((token) => {
        let api = new FileshareApi();
        let userId = user.sub
        return api.downloadFile(userId, fileName, token);
      })
      .then((res) => {
        // response is undefined
      })
      .catch((err) => console.log(err))
  }
  return (
    <Tooltip title="Download">
      <IconButton
        aria-label="download file"
        variant="contained"
        className="download-button"
        onClick={() => { downloadFile(props.fileName) }}
        size="large">
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
  );
}

// define the columns for the file browser given the screenWidth in pixels
function getColumns(screenWidth) {
  let nameWidth = screenWidth / 2;
  let typeWidth = 70;
  if (screenWidth < 500) {
    nameWidth = 150;
  }
  let columns = [
    { field: 'filename', headerName: 'Name', flex: 1 },
    { field: 'filetype', headerName: 'Type', width: typeWidth, headerAlign: 'left', align: 'left' },
    {
      // col3 has a value representing the file names and contains a download button
      field: 'downloadfile', headerName: " ", width: 60, align: "left",
      renderCell: (params) => (
        <DownloadButton fileName={params.value} />
      )
    }
  ];
  return columns;
}

export default function FileBrowser(props) {
  const [selection, setSelection] = useState([]); //initialize as empty array
  const { getAccessTokenSilently, user, isAuthenticated, isLoading } = useAuth0();
  const [isDeleting, setIsDeleting] = useState(false);

  // keep track of screen width for resizing the grid for a mobile view
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  window.onresize = () => setScreenWidth(window.innerWidth);

  // delete all the files currently selected in the grid by calling the api
  const deleteSelectedFiles = () => {
    let selectedFiles = selection;
    setSelection([]);
    setIsDeleting(true);
    let deleteCount = 0;
    selectedFiles.forEach(fileId => {
      getAccessTokenSilently({ audience: '/files' }) // TODO: optimize by putting this outside the loop
        .then((token) => {
          let api = new FileshareApi();
          let userId = user.sub
          return api.deleteFile(userId, fileId, token);
        })
        .then((res) => {
          console.log(res);
          deleteCount++;
          // if this file is the last one to be deleted, refresh list of files displayed
          if (selectedFiles.length == deleteCount) {
            // notify file manager to refresh the list of files
            // pass in a unique string trigger the useEffect api call
            props.refresh(fileId + 'deleted');
            setIsDeleting(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDeleting(false);
        });
    });
  }

  let browser = <CircularProgress size="6rem" color="secondary" className="loading-spinner" />

  // if there is not and error or process occuring then the browser is displayed
  if (!props.loading && !props.error && !isDeleting) {
    browser =
      (
        <div id="file-browser" aria-label="file browser interface">
              <DataGrid rows={props.data} checkboxSelection sortModel={sortModel} rowsPerPageOptions={[]}
                columns={getColumns(screenWidth).map((column) => ({
                  ...column,
                  disableClickEventBubbling: true,
                }))}
                onSelectionChange={(newSelection) => {
                  setSelection(newSelection.rowIds);
                }}
                components={{
                  noRowsOverlay: () => { return <NoFilesOverlay /> }
                }}
                hideFooterSelectedRowCount={true}
                sx={{
                  color: '#fff'
                }}
                autoHeight
              />
        </div>
      )
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkTheme}>
        <div id="my-files">
          <div id="file-header">
            <h2>My files</h2>
            <DeleteButton numFiles={selection.length} handleClick={deleteSelectedFiles} />
          </div>
          {browser}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>

  );
}