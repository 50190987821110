import React from 'react';
import { Select, MenuItem, InputLabel, FormControl, TextField, Button, Snackbar } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { TrendingUpRounded } from '@material-ui/icons';
import $ from 'jquery';

const darkTheme = createTheme({
    palette: {
        type: 'dark',
    },
});

export default class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: { name: false, phone: false, email: false, interest: false, message: false },
            name: "", phone: "", email: "", interest: "", message: "", openSnack: false
        };
    }

    // validates the contents of the form and sends an email if valid
    // modifies state according to whether or not the form was valid
    handleSubmit(event) {
        this.setState((state, props) => {
            let errors = { name: false, phone: false, email: false, interest: false, message: false };
            if (state.name === undefined || state.name === "") {
                errors.name = true;
            }
            if (state.phone === undefined || state.phone === "") {
                errors.phone = true;
            }
            if (state.email === undefined || state.email === "") {
                errors.email = true;
            }
            if (state.interest === undefined || state.interest === "") {
                errors.interest = true;
            }
            if (state.message === undefined || state.message === "") {
                errors.message = true;
            }

            if (this.formIsValid(errors)) {
                let emailInfo = { name: state.name, phone: state.phone, email: state.email, interest: state.interest, message: state.message };
                this.sendEmail(emailInfo);

                // reset the form and display snackbar
                return {
                    errors: { name: false, phone: false, email: false, interest: false, message: false },
                    name: "", phone: "", email: "", interest: "", message: "", openSnack: true
                };
            }

            return { errors: errors, openSnack: true };
        });
    }

    // for a given object sends an email listing the given object's attributes
    sendEmail(inputData) {
        $.ajax({
            url: "https://formsubmit.co/ajax/frontdesk@d2.financial",
            method: "POST",
            data: inputData
        });
    }

    // takes an object listing potential errors and returns if valid
    formIsValid(errors) {
        errors = Object.values(errors);
        if (errors.includes(true))
            return false;
        return true;
    }

    handleCloseSnack(event) {
        this.setState({ openSnack: false });
    }

    updateName(event) {
        this.setState({ name: event.target.value });
    }
    updatePhone(event) {
        this.setState({ phone: event.target.value });
    }
    updateEmail(event) {
        this.setState({ email: event.target.value });
    }
    updateInterest(event) {
        this.setState({ interest: event.target.value });
    }
    updateMessage(event) {
        this.setState({ message: event.target.value });
    }

    render() {
        // define alert for snackbar
        let alert = <Alert variant="filled" severity="error">Error: Please complete the missing information</Alert>
        if (this.formIsValid(this.state.errors))
            alert = <Alert variant="filled" severity="success">Your message has been sent!</Alert>

        return (
            <form>
                <Snackbar open={this.state.openSnack} onClose={(evt) => this.handleCloseSnack(evt)} autoHideDuration={6000} >
                    {alert}
                </Snackbar>
                <h2>send us a message</h2>
                <ThemeProvider theme={darkTheme}>
                    <TextField id="full-name-input" className="contact-input" label="full name" variant="outlined"
                        onChange={(evt) => this.updateName(evt)} value={this.state.name} error={this.state.errors.name} />
                    <TextField id="phone-input" className="contact-input" label="phone" variant="outlined"
                        onChange={(evt) => this.updatePhone(evt)} value={this.state.phone} error={this.state.errors.phone} />
                    <TextField id="email-input" className="contact-input" label="email" variant="outlined"
                        onChange={(evt) => this.updateEmail(evt)} value={this.state.email} error={this.state.errors.email} />

                    <FormControl variant="outlined" className="contact-input">
                        <InputLabel id="interest-select-label">Interest</InputLabel>
                        <Select
                            labelId="interest-select-label"
                            id="interest-select"
                            label="Interest"
                            onChange={(evt) => this.updateInterest(evt)}
                            value={this.state.interest}
                            error={this.state.errors.interest}
                        >
                            <MenuItem value="consultation">consultation</MenuItem>
                            <MenuItem value="question">question</MenuItem>
                            <MenuItem value="appointment">appointment</MenuItem>
                            <MenuItem value="contest">contest</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        id="message-input"
                        className="contact-input"
                        label="message"
                        multiline
                        rows={10}
                        variant="outlined"
                        onChange={(evt) => this.updateMessage(evt)}
                        value={this.state.message}
                        error={this.state.errors.message} />
                    <Button
                        variant="contained"
                        contentDescription="send the information contained in the form"
                        onClick={(evt) => this.handleSubmit(evt)}>send
                        </Button>
                </ThemeProvider>
            </form>
        );
    }
}