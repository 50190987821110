import React from "react";
import {
  MenuItem,
  TextField,
  Button,
  Snackbar,
  Paper,
} from "@mui/material";
import { Alert } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";

export default function ContestEntry() {
  const [contest, setContest] = React.useState("");
  const [name, setName] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [alert, setAlert] = React.useState(<></>);
  const { user } = useAuth0();
  const [ loading, setLoading ] = React.useState(false);
  const handleChange = (event) => {
    setContest(event.target.value);
  };
  const handleSubmit = (event) => {
    let errors = { email: false, contest: false, name: false };

    if (contest === undefined || contest === "") {
      errors.contest = true;
    }
    if (name === undefined || name === "") {
      errors.name = true;
    }

    if (formIsValid(errors)) {
      let emailInfo = { email: user.email, name: name, contest: contest };
      setLoading(true)
      sendEmail(emailInfo);
      // reset the form and display snackbar

      return {};
    }
    setAlert(
      <Alert variant="filled" severity="error">
        {errors.contest && errors.name
          ? "Contest Entry and Name"
          : errors.contest
          ? "Contest Entry"
          : errors.name
          ? "Name"
          : ""}{" "}
        required
      </Alert>
    );
    setOpenSnack(true);
    return { errors: errors, openSnack: true };
  };

  // for a given object sends an email listing the given object's attributes
  const sendEmail = (inputData) => {
    fetch("https://formsubmit.co/ajax/frontdesk@d2.financial", {
      method: "POST",
      data: inputData,
    }).then((response) => {
      if (!response.ok) {
        setLoading(false);
        setAlert(
          <Alert variant="filled" severity="error">
            Error with form submission
          </Alert>
        );
        setOpenSnack(true);
      } else {
        setName("");
        setContest("");
        setLoading(false);
        setAlert(
          <Alert variant="filled" severity="success">
            Your entry been sent!
          </Alert>
        );
        setOpenSnack(true);
      }
    });
  };

  // takes an object listing potential errors and returns if valid
  const formIsValid = (errors) => {
    errors = Object.values(errors);
    if (errors.includes(true)) return false;
    return true;
  };

  const handleCloseSnack = (event) => {
    setOpenSnack(false);
  };

  const updateName = (event) => {
    setName(event.target.value);
  };
  return (
    <div>
      <Snackbar
        open={openSnack}
        onClose={(evt) => handleCloseSnack(evt)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
      >
        {alert}
      </Snackbar>

      <Box sx={{ minWidth: 120, maxWidth: "md", margin: "auto" }}>
        <Paper elevation={4} key={"contest-entry-surface"}>
          <Stack component={"form"} spacing={2} p={2}>
            <DialogContent>
              <DialogTitle>Contest Entry</DialogTitle>
              <DialogContentText>
                Select a contest for a chance to win!
              </DialogContentText>
            </DialogContent>
            <TextField
              id="name-entry"
              value={name}
              label="Name"
              variant="outlined"
              required
              onChange={updateName}
            />
            <TextField
              value={contest}
              onChange={handleChange}
              select // tell TextField to render select
              id="contest-select"
              required
              label="Select a Contest"
            >
              <MenuItem value="NEEDTOBREATHE" disabled>
                NEEDTOBREATHE 05/11
              </MenuItem>
              <MenuItem value="Avril Lavigne" disabled>
                Avril Lavigne 05/25
              </MenuItem>
              <MenuItem value="The Doobie Brothers" disabled>
                The Doobie Brothers06/16
              </MenuItem>
              <MenuItem value="Hootie and the Blowfish" disabled>
                Hootie and the Blowfish 07/20
              </MenuItem>
              <MenuItem value="Niall Horan" disabled>
                Niall Horan 07/23
              </MenuItem>
              <MenuItem value="Thirty Seconds to Mars" disabled>
                Thirty Seconds to Mars 07/26
              </MenuItem>
              <MenuItem value="Alanis Morissette" disabled>
                Alanis Morissette 08/03
              </MenuItem>
              <MenuItem value="Slightly Stoopid" disabled>
                Slightly Stoopid and Dirty Heads 08/17
              </MenuItem>
              <MenuItem value="Limp Bizket" disabled>Limp Bizkit 08/20</MenuItem>
              <MenuItem value="Train and Reo Speedwagon" disabled>
                Train and Reo Speedwagon 08/31
              </MenuItem>
              <MenuItem value="Doobie Brothers">
                Doobie Brothers 09/06
              </MenuItem>
              <MenuItem value="Lynard Skynard and ZZ Top">
                Lynard Skynard and ZZ Top 09/21
              </MenuItem>
              <MenuItem value="Gloria Trevi">
                Gloria Trevi 09/22
              </MenuItem>
              <MenuItem value="Seahawks vs Dolphins">
                Seahawks vs Dolphins 09/22
              </MenuItem>
              <MenuItem value="Seahawks vs  Giants">
              Seahawks vs  Giants 10/6
              </MenuItem>
              <MenuItem value="Seahawks vs Rams">
              Seahawks vs Rams 11/3
              </MenuItem>
              <MenuItem value="Seahawks vs Cardinals">
              Seahawks vs Cardinals 11/24
              </MenuItem>


            </TextField>
            {loading ? (
              <Box sx={{margin: 'auto'}}><CircularProgress  /></Box>
            ) : (
              <Button onClick={(evt) => handleSubmit(evt)}>Submit</Button>
            )}
          </Stack>
        </Paper>
      </Box>
    </div>
  );
}
// export class ContestEntryOLD extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       errors: { email: false, contestEntry: false, name: false },
//       email: "",
//       contestEntry: "",
//       name: "",
//       openSnack: false,
//     };
//   }

//   // validates the contents of the form and sends an email if valid
//   // modifies state according to whether or not the form was valid
//   handleSubmit(event) {
//     this.setState((state, props) => {
//       let errors = { email: false, contestEntry: false, name: false };
//       if (state.email === undefined || state.email === "") {
//         errors.email = true;
//       }
//       if (state.contestEntry === undefined || state.contestEntry === "") {
//         errors.contestEntry = true;
//       }
//       if (state.name === undefined || state.name === "") {
//         errors.name = true;
//       }
//       if (this.formIsValid(errors)) {
//         let emailInfo = {
//           email: state.email,
//           contestEntry: state.contestEntry,
//           name: state.name,
//         };
//         this.sendEmail(emailInfo);

//         // reset the form and display snackbar
//         return {
//           errors: { email: false, contestEntry: false, name: false },
//           email: "",
//           contestEntry: "",
//           name: "",
//           openSnack: true,
//         };
//       }

//       return { errors: errors, openSnack: true };
//     });
//   }

//   // for a given object sends an email listing the given object's attributes
//   sendEmail(inputData) {
//     $.ajax({
//       url: "https://formsubmit.co/ajax/frontdesk@d2.financial",
//       method: "POST",
//       data: inputData,
//     });
//   }

//   // takes an object listing potential errors and returns if valid
//   formIsValid(errors) {
//     errors = Object.values(errors);
//     if (errors.includes(true)) return false;
//     return true;
//   }

//   handleCloseSnack(event) {
//     this.setState({ openSnack: false });
//   }

//   updateEmail(event) {
//     this.setState({ email: event.target.value });
//   }
//   updateContestEntry(event) {
//     this.setState({ contestEntry: event.target.value });
//   }
//   updateName(event) {
//     this.setState({ name: event.target.value });
//   }
//   render() {
//     // define alert for snackbar
//     let alert = (
//       <Alert variant="filled" severity="error">
//         Error: Please complete the missing information
//       </Alert>
//     );
//     if (this.formIsValid(this.state.errors))
//       alert = (
//         <Alert variant="filled" severity="success">
//           Your entry been sent!
//         </Alert>
//       );

//     return (
//       <form>
//         <Snackbar
//           open={this.state.openSnack}
//           onClose={(evt) => this.handleCloseSnack(evt)}
//           autoHideDuration={6000}
//         >
//           {alert}
//         </Snackbar>
//         <DialogTitle>Contest Entry</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Enter your information and select a contest for a chance to win!
//           </DialogContentText>
//           {/* <div className="form-group mt-3 mb-3">
//                         <input type="name" className="form-control" id="name" email="name" aria-describedby="name" placeholder="Enter name" onChange={event => this.updateName(event)} />
//                     </div>
//                     <div className="form-group mt-3 mb-3">
//                         <input type="email" className="form-control" id="email" email="email" aria-describedby="email" placeholder="Enter email" onChange={event => this.updateEmail(event)} />
//                     </div> */}
//           <div className="form-group">
//             <select
//               id="contest"
//               email="contest"
//               className="form-select mt-3 w-100"
//               value={this.state.contestEntry}
//               onChange={(event) => this.updateContestEntry(event)}
//             >
//               <option>Select a contest</option>
//               <option value="NEEDTOBREATHE" disabled>
//                 NEEDTOBREATHE 05/11
//               </option>
//               <option value="Avril Lavigne" disabled>
//                 Avril Lavigne 05/25
//               </option>
//               <option value="The Doobie Brothers" disabled>
//                 The Doobie Brothers06/16
//               </option>
//               <option value="Hootie and the Blowfish" disabled>
//                 Hootie and the Blowfish 07/20
//               </option>
//               <option value="Niall Horan" disabled>
//                 Niall Horan 07/23
//               </option>
//               <option value="Thirty Seconds to Mars" disabled>
//                 Thirty Seconds to Mars 07/26
//               </option>
//               <option value="Alanis Morissette" disabled>
//                 Alanis Morissette 08/03
//               </option>
//               <option value="Slightly Stoopid">
//                 Slightly Stoopid and Dirty Heads 08/17
//               </option>
//               <option value="Limp Bizket">Limp Bizkit 08/20</option>
//               <option value="Train and Reo Speedwagon">
//                 Train and Reo Speedwagon 08/31
//               </option>
//               <option value="Lynard Skynard and ZZ Top">
//                 Lynard Skynard and ZZ Top 09/21
//               </option>
//               <option value="Gloria Trevi">Gloria Trevi 09/22</option>
//             </select>
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={(evt) => this.handleSubmit(evt)}>Submit</Button>
//         </DialogActions>
//       </form>
//     );
//   }
// }
