import React from 'react';
import NavBar from "./home/components/NavBar.js";
import { HomePage, Info } from "./home/components/HomePage.js";
import AboutPage from "./home/components/AboutPage.js";
import Footer from "./home/components/Footer.js";
import Portal from "./portal/Portal.js";
import NewClientForm from "./portal/components/forms/NewClient.js"
import NewBusinessForm from './portal/components/forms/NewBusiness.js';
import { BrowserRouter as Router, Route, Link, Routes, redirect, Outlet, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FileManager } from './portal/components/FileManager.js'
import FormParent from './portal/components/FormParent.js';
import { DefaultView } from './home/Home.js';
import Billing from './portal/components/Billing.js';
import ContestEntry from './portal/components/ContestEntry.js';
function App() {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
    <div id="App">
      <Router basename='/'>
        <Routes>
          <Route path= {'/'} element={<DefaultView/>}>
            <Route path='' element={<HomePage/>} />
            <Route path={'about'} element={<AboutPage/>} />
            <Route path={'info'} element={<Info/>} />
            <Route path={'forms/new_client'} element={<NewClientForm/>} />
          </Route>
          <Route path={'/portal'} element={<Portal/>} replace>

            <Route path={'files'} element={<FileManager/>}/>
            <Route path={'forms'} element={<FormParent/>}>
              <Route path={'new-client'} element={<NewClientForm/>} />
              <Route path={'new-business'} element={<NewBusinessForm/>} />
              <Route path={"*"} element={<></>} />
            </Route>
            <Route path={'contest-entry'} element={<ContestEntry/>}/>
            {/* <Route path={'billing'} element={<Billing/>}/> */}
            <Route
                index
                element={<Navigate to="/portal/files" replace />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
    </ThemeProvider>

  );
}

export default App;
