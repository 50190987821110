import React from 'react';
import { useEffect, useState } from 'react';
import logo from '../../img/logo_light.svg'
import { Link, withRouter } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ContestEntry from '../../portal/components/ContestEntry.js'
import { useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


function NavBar(){
    const darkTheme = createTheme(adaptV4Theme({
        palette: {
            mode: 'dark',
        },
    }));
    const[navOpacity, setNavOpacity] = React.useState('nav-colored')
    const location = useLocation();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };



    // useEffect(() => {
    //     //listens for any route changes
    //     this.props.history.listen(() => {
    //         setState({
    //             currentRoute: window.location.pathname
    //         });
    //         // if route has been changed to about refresh the page
    //         // so that the facebook plugin will load
    //         if (window.location.pathname === '/about') {
    //             window.location.reload();
    //         })
    //     }, [location])

    //listen for scroll event
    // useEffect(() => {
    //       window.addEventListener("scroll", setNavOpacity('nav-colored'), true);
    //       return () => {
    //         window.removeEventListener("scroll", setNavOpacity('nav-colored'), true);
    //       };
    // }, [])

        let links = {
            logo: (<Link to={process.env.PUBLIC_URL + "/"}><img src={logo} alt="go to homepage" /></Link>),
            schedule: (<a href="https://outlook.office365.com/owa/calendar/DoubleDFinancial@doubledfinancial.com/bookings/">schedule online</a>),
            services: (<Link href="/#services-top">services</Link>),
            contact: (<Link href="/#contact-top">contact</Link>),
            about: (<Link to={process.env.PUBLIC_URL + '/about'}>about</Link>),
            upload: (<PortalLink />),
            // contest:(<a onClick={handleClickOpen} style={{'cursor': 'pointer', 'color': 'white'}}>contest entry</a>),

        }

        // if the current route doesn't have services and contact sections
        if (location !== "/home/" && location !== "/home/info") {
            links.services = (<Link to={process.env.PUBLIC_URL + "/info"}>services</Link>);
            links.contact = (<Link to={process.env.PUBLIC_URL + "/info"}>contact</Link>);
        }

        return (
            <div>
            <div>
                <nav className={navOpacity} style={{position: "fixed"}}>
                    {links.logo}
                    <ul className="desktop-nav-links">
                        <li>
                            {links.contest}
                        </li>
                        <li>
                            {links.schedule}
                        </li>
                        <li>
                            {links.services}
                        </li>
                        <li>
                            {links.about}
                        </li>
                        <li>
                            {links.contact}
                        </li>
                        <li>
                            <PortalLink />
                        </li>
                    </ul>
                    <DropdownMenu links={links} />
                </nav>
            </div>
            {/* <div>
            <Dialog open={open} onClose={handleClose}>
                <ContestEntry/>
            </Dialog>
            </div> */}
        </div>
        );
    }

class DropdownMenu extends React.Component {
    render() {
        return (
            <div className="dropdown">
                <button className="dropbtn">Menu</button>
                <div className="dropdown-content">
                    {this.props.links.services}
                    {this.props.links.about}
                    {this.props.links.contact}
                    {this.props.links.upload}
                    {this.props.links.schedule}
                    {this.props.links.contest}

                </div>
            </div>
        );
    }
}

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <a className="portal-link" tabIndex="0" onClick={() => loginWithRedirect()}>login</a>;
};

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <a className="portal-link" tabIndex="0" onClick={() => logout({ returnTo: window.location.origin })}>
            logout
        </a>
    );
};

const PortalLink = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
  
    if (isLoading) {
      return <div>Loading ...</div>;
    }
    if (isAuthenticated) {
        if (window.location.pathname === '/portal') {
            return <LogoutButton />
        }
        return(
            <div>
            <Link reloadDocument className="portal-link" tabindex="0" to={'/portal'}>account</Link>
            </div>
        )
    } else {
        return <LoginButton />;
    }
  };

export default NavBar;