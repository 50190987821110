import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useFormik, resetForm } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline, Divider } from "@mui/material";
import { PDFDocument } from "pdf-lib";
import { useAuth0 } from "@auth0/auth0-react";
import { FileshareApi } from "../../../api/fileshareApi";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
const darkTheme = createTheme(adaptV4Theme({
  palette: {
    mode: "dark",
  },
}));

const validationSchema = yup.object({
    name: yup.string("Enter your name").required("Name is required"),
    socialSecurity: yup
      .string("Enter your Social Security Number")
      .required("Social Security Number is required"),
    bday: yup.string("Enter your birthday").required("Birthday is required"),
    occupation: yup
      .string("Enter your occupation")
      .required("Occupation is required"),
    streetAdress: yup
      .string("Enter your address")
      .required("Address is required"),
    state: yup.string("Enter your state").required("State is required"),
    city: yup.string("Enter your city").required("City is required"),
    zip: yup.string("Enter your zip code").required("Zip code is required"),
    tel: yup
      .string("Enter your phone number")
      .required("Phone number is required"),
    email: yup
      .string("Enter your email")
      .required("Email is required")
});

export default function NewBusinessForm() {
  const { getAccessTokenSilently, user, isAuthenticated, isLoading } = useAuth0();
  const [showSpouseForm, setshowSpouseForm] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const handleOpenSuccessSnackbar = () => {
    setSubmitSuccess(true);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  }
  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
        var vals = JSON.parse(JSON.stringify(values, null, 2))
        var formPdfBytes
        var pdfDoc
        var form
        var clientName
        fetch('/forms/New Client - Personal Info (1)_fillable.pdf').then(res => res.arrayBuffer())
        .then(buffer =>  pdfDoc = PDFDocument.load(buffer)
        .then((pdf)=>{pdfDoc = pdf; form = pdfDoc.getForm()}).then(()=>{

        Object.keys(vals).forEach(function(key, index) {
            try{
                console.log(key)
                if (key == "dateOfMarriage"){
                    var field = form.getTextField('spouseDateOfMarriage')
                    field.setText(vals[key])
                }

                var field = form.getTextField(key)
                field.setText(vals[key])
              } catch (e) {
                console.error(e.message);
            }
          })
        var processed_val 
        var field
        //date
        let today = new Date().toLocaleDateString()
        form.getTextField('date').setText(today)
        //do first name for taxpayer
        clientName = vals['name'].trim()
        let nameArr = vals['name'].trim().split(" ")
        form.getTextField('lastName').setText(nameArr.pop())
        form.getTextField('name').setText(nameArr.join(" "))


        if (Object.keys(vals).includes('spouseName')){
            let nameArr = vals['spouseName'].trim().split(" ")
            form.getTextField('spouseLastName').setText(nameArr.pop())
            form.getTextField('spouseName').setText(nameArr.join(" "))
        }
        if (['city', 'state', 'zip' ].every(v => Object.keys(vals).includes(v))){
            form.getTextField('city_zip').setText(`${vals['city']}, ${vals['state']}, ${vals['zip']}`)
        }
        if (['spouseCity', 'spouseState', 'spouseZip' ].every(v => Object.keys(vals).includes(v))){
            form.getTextField('spouseCity_zip').setText(`${vals['spouseCity']}, ${vals['spouseState']}, ${vals['spouseZip']}`)
        }

        pdfDoc.save().then((bytes)=>{
            //const blob = new Blob([bytes], { type: "application/pdf;charset=utf-8" });
            var file = new File([bytes], `New Client Information - ${clientName}.pdf`, { type: "application/pdf;charset=utf-8" });
            getAccessTokenSilently({ audience: '/files' })
                .then((token) => {
                    let api = new FileshareApi();
                    let userId = user.sub
                    return api.uploadFileList(userId, [file], token);
                })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                })

        })
        handleOpenSuccessSnackbar()
        resetForm()
        }))
      }})
  if (!isAuthenticated){
    console.log('asdf')
        return (
          <Box textAlign={"center"} m={"5em"} pt={"5em"}>
          <h3 style={{ color: "#fff" }}>Please log in to complete this form</h3>
        </Box>        )
    }

  return (   
    <Box p={"3em"}>
      <Snackbar open={submitSuccess} autoHideDuration={6000} onClose={handleCloseSuccessSnackbar}>
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          New Business Form Submitted
        </Alert>
      </Snackbar>
      <form onSubmit={formik.handleSubmit}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={darkTheme}>
            <Box textAlign={"left"} mt={"2em"}>
              <h5 style={{ color: "#fff" }}>Business Information</h5>
            </Box>
            <Box>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Owner Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="owner-tel"
                name="tel"
                label="Owner Telephone Number"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Owner Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                autoComplete="on"
              />

              <Divider sx={{marginY: '1rem'}} />

              <TextField
                fullWidth
                id="businessName"
                name="businessName"
                label="Business Name"
                value={formik.values.businessName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                helperText={formik.touched.businessName && formik.errors.businessName}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="dbaName"
                name="dbaName"
                label="DBA Name"
                value={formik.values.dbaName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.dbaName && Boolean(formik.errors.dbaName)}
                helperText={formik.touched.dbaName && formik.errors.dbaName}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="businessAddress"
                name="businessAddress"
                label="Principal Business Address"
                value={formik.values.businessAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.businessAddress && Boolean(formik.errors.businessAddress)}
                helperText={formik.touched.businessAddress && formik.errors.businessAddress}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="mailingAddress"
                name="mailingAddress"
                label="Mailing Address (If different from Principal Address)"
                value={formik.values.mailingAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mailingAddress && Boolean(formik.errors.mailingAddress)}
                helperText={formik.touched.mailingAddress && formik.errors.mailingAddress}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="businessTel"
                name="businessTel"
                label="Business Phone Number"
                value={formik.values.businessTel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.businessTel && Boolean(formik.errors.businessTel)}
                helperText={formik.touched.businessTel && formik.errors.businessTel}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="businessEmail"
                name="businessEmail"
                label="Business Email"
                value={formik.values.businessEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.businessEmail && Boolean(formik.errors.businessEmail)}
                helperText={formik.touched.businessEmail && formik.errors.businessEmail}
                autoComplete="on"
              />

              <Divider sx={{marginY: '1rem'}} />
              <FormControl>

              <FormLabel id="demo-row-radio-buttons-group-label">Business Entity</FormLabel>
              <RadioGroup
                
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="Sole Proprietor or/Self Employed" control={<Radio />} label="Sole Proprietor or/Self Employed" />
                <FormControlLabel value="LLCs" control={<Radio />} label="LLCs" />
                <FormControlLabel value="S-Corp" control={<Radio />} label="S-Corp" />
                <FormControlLabel value="C-Corp" control={<Radio />} label="C-Corp" />
                <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" />
                <FormControlLabel value="Exempt Organization" control={<Radio />} label="Exempt Organization" />

              </RadioGroup>
              </FormControl>

              <TextField
                fullWidth
                multiline
                id="description"
                name="description"
                label="Job Description/Nature of Business"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                autoComplete="on"
              />

              <TextField
                fullWidth
                id="openingDate"
                name="openeingDate"
                label="Opening Date"
                value={formik.values.openingDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.openingDate &&
                  Boolean(formik.errors.openingDate)
                }
                helperText={
                  formik.touched.openingDate && formik.errors.openingDate
                }
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="ein"
                name="ein"
                label="EIN#"
                value={formik.values.ein}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.ein &&
                  Boolean(formik.errors.ein)
                }
                helperText={
                  formik.touched.ein && formik.errors.ein
                }
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="ubi"
                name="ubi"
                label="UBI#"
                value={formik.values.ubi}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.ubi &&
                  Boolean(formik.errors.ubi)
                }
                helperText={
                  formik.touched.ubi && formik.errors.ubi
                }
                autoComplete="on"
              />
              <Divider sx={{marginY: '1rem'}} />

              <Box textAlign={"left"} mt={"2em"}>
                <h5 style={{ color: "#fff" }}>Bank Information</h5>
              </Box>
              <TextField
                fullWidth
                id="bankName"
                name="bankName"
                label="Bank Name"
                value={formik.values.bankName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                helperText={formik.touched.bankName && formik.errors.bankName}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="accountNumber"
                name="accountNumber"
                label="Account Number"
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.accountNumber &&
                  Boolean(formik.errors.accountNumber)
                }
                helperText={
                  formik.touched.accountNumber && formik.errors.accountNumber
                }
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="routingNumber"
                name="routingNumber"
                label="Routing Number"
                value={formik.values.routingNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.routingNumber &&
                  Boolean(formik.errors.routingNumber)
                }
                helperText={
                  formik.touched.routingNumber && formik.errors.routingNumber
                }
                autoComplete="on"
              />
            </Box>
            <Divider sx={{marginY: '1rem'}} />
            <Box textAlign={"left"} mt={"2em"}>
                <h5 style={{ color: "#fff" }}>Bank Credit Card Information</h5>
            </Box>
            <TextField
                fullWidth
                id="ccBankName"
                name="ccBankName"
                label="Bank Name"
                value={formik.values.ccBankName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.ccBankName && Boolean(formik.errors.ccBankName)}
                helperText={formik.touched.ccBankName && formik.errors.ccBankName}
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="ccAccountNumber"
                name="ccAccountNumber"
                label="Account Number"
                value={formik.values.ccAccountNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.ccAccountNumber &&
                  Boolean(formik.errors.ccAccountNumber)
                }
                helperText={
                  formik.touched.ccAccountNumber && formik.errors.ccAccountNumber
                }
                autoComplete="on"
              />
              <TextField
                fullWidth
                id="ccRoutingNumber"
                name="ccRoutingNumber"
                label="Routing Number"
                value={formik.values.ccRoutingNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.ccRoutingNumber &&
                  Boolean(formik.errors.ccRoutingNumber)
                }
                helperText={
                  formik.touched.ccRoutingNumber && formik.errors.ccRoutingNumber
                }
                autoComplete="on"
              />
            <Divider sx={{marginY: '1rem'}} />

            <Box textAlign={"left"} mt={"2em"}>
              <h5 style={{ color: "#fff" }}>Additional Information</h5>
            </Box>
            <Box textAlign={"left"} mt={"2em"}>
              <p style={{ color: "#fff" }}>If the business has more than 1 owner/officer, please fill out the following information:</p>
            </Box>

              <Box textAlign={"center"} mt={"2em"}>
                <h5 style={{ color: "#fff" }}>Officer 1</h5>
              </Box>
              <TextField
                fullWidth
                id="officer1FullName"
                name="officer1FullName"
                label="Full Name"
                value={formik.values.officer1FullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer1FullName &&
                  Boolean(formik.errors.officer1FullName)
                }
                helperText={
                  formik.touched.officer1FullName &&
                  formik.errors.officer1FullName
                }
              />
              <TextField
                fullWidth
                id="officer1SocialSecurityNumber"
                name="officer1SocialSecurityNumber"
                label="SSN"
                value={formik.values.officer1SocialSecurityNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer1SocialSecurityNumber &&
                  Boolean(formik.errors.officer1SocialSecurityNumber)
                }
                helperText={
                  formik.touched.officer1SocialSecurityNumber &&
                  formik.errors.officer1SocialSecurityNumber
                }
              />

              <TextField
                fullWidth
                id="officer1DateOfBirth"
                name="officer1DateOfBirth"
                label="Date of Birth"
                value={formik.values.officer1DateOfBirth}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer1DateOfBirth &&
                  Boolean(formik.errors.officer1DateOfBirth)
                }
                helperText={
                  formik.touched.officer1DateOfBirth &&
                  formik.errors.officer1DateOfBirth
                }
              />
              <TextField
                fullWidth
                id="officer1Title"
                name="officer1Title"
                label="Title"
                value={formik.values.officer1Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer1Title &&
                  Boolean(formik.errors.officer1Title)
                }
                helperText={
                  formik.touched.officer1Title &&
                  formik.errors.officer1Title
                }
              />
              <TextField
                fullWidth
                id="officer1Email"
                name="officer1Email"
                label="Email Address"
                value={formik.values.officer1Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer1Email &&
                  Boolean(formik.errors.officer1Email)
                }
                helperText={
                  formik.touched.officer1Email &&
                  formik.errors.officer1Email
                }
              />
              <TextField
                fullWidth
                id="officer1Tel"
                name="officer1Tel"
                label="Phone Number"
                value={formik.values.officer1Tel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer1Tel &&
                  Boolean(formik.errors.officer1Tel)
                }
                helperText={
                  formik.touched.officer1Tel &&
                  formik.errors.officer1Tel
                }
              />
              <TextField
                fullWidth
                id="officer1Ownership"
                name="officer1Ownership"
                label="Ownership Percentage (%)"
                value={formik.values.officer1Ownership}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer1Ownership &&
                  Boolean(formik.errors.officer1Ownership)
                }
                helperText={
                  formik.touched.officer1Ownership &&
                  formik.errors.officer1Ownership
                }
              />
  
              <Box textAlign={"center"} mt={"2em"}>
                <h5 style={{ color: "#fff" }}>Officer 2</h5>
              </Box>
              <TextField
                fullWidth
                id="officer2FullName"
                name="officer2FullName"
                label="Full Name"
                value={formik.values.officer2FullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer2FullName &&
                  Boolean(formik.errors.officer2FullName)
                }
                helperText={
                  formik.touched.officer2FullName &&
                  formik.errors.officer2FullName
                }
              />
              <TextField
                fullWidth
                id="officer2SocialSecurityNumber"
                name="officer2SocialSecurityNumber"
                label="SSN"
                value={formik.values.officer2SocialSecurityNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer2SocialSecurityNumber &&
                  Boolean(formik.errors.officer2SocialSecurityNumber)
                }
                helperText={
                  formik.touched.officer2SocialSecurityNumber &&
                  formik.errors.officer2SocialSecurityNumber
                }
              />

              <TextField
                fullWidth
                id="officer2DateOfBirth"
                name="officer2DateOfBirth"
                label="Date of Birth"
                value={formik.values.officer2DateOfBirth}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer2DateOfBirth &&
                  Boolean(formik.errors.officer2DateOfBirth)
                }
                helperText={
                  formik.touched.officer2DateOfBirth &&
                  formik.errors.officer2DateOfBirth
                }
              />
              <TextField
                fullWidth
                id="officer2Title"
                name="officer2Title"
                label="Title"
                value={formik.values.officer2Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer2Title &&
                  Boolean(formik.errors.officer2Title)
                }
                helperText={
                  formik.touched.officer2Title &&
                  formik.errors.officer2Title
                }
              />
              <TextField
                fullWidth
                id="officer2Email"
                name="officer2Email"
                label="Email Address"
                value={formik.values.officer2Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer2Email &&
                  Boolean(formik.errors.officer2Email)
                }
                helperText={
                  formik.touched.officer2Email &&
                  formik.errors.officer2Email
                }
              />
              <TextField
                fullWidth
                id="officer2Tel"
                name="officer2Tel"
                label="Phone Number"
                value={formik.values.officer2Tel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer2Tel &&
                  Boolean(formik.errors.officer2Tel)
                }
                helperText={
                  formik.touched.officer2Tel &&
                  formik.errors.officer2Tel
                }
              />
              <TextField
                fullWidth
                id="officer2Ownership"
                name="officer2Ownership"
                label="Ownership Percentage (%)"
                value={formik.values.officer2Ownership}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer2Ownership &&
                  Boolean(formik.errors.officer2Ownership)
                }
                helperText={
                  formik.touched.officer2Ownership &&
                  formik.errors.officer2Ownership
                }
              />

              <Box textAlign={"center"} mt={"2em"}>
                <h5 style={{ color: "#fff" }}>Officer 3</h5>
              </Box>
              <TextField
                fullWidth
                id="officer3FullName"
                name="officer3FullName"
                label="Full Name"
                value={formik.values.officer3FullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer3FullName &&
                  Boolean(formik.errors.officer3FullName)
                }
                helperText={
                  formik.touched.officer3FullName &&
                  formik.errors.officer3FullName
                }
              />
              <TextField
                fullWidth
                id="officer3SocialSecurityNumber"
                name="officer3SocialSecurityNumber"
                label="SSN"
                value={formik.values.officer3SocialSecurityNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer3SocialSecurityNumber &&
                  Boolean(formik.errors.officer3SocialSecurityNumber)
                }
                helperText={
                  formik.touched.officer3SocialSecurityNumber &&
                  formik.errors.officer3SocialSecurityNumber
                }
              />

              <TextField
                fullWidth
                id="officer3DateOfBirth"
                name="officer3DateOfBirth"
                label="Date of Birth"
                value={formik.values.officer3DateOfBirth}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer3DateOfBirth &&
                  Boolean(formik.errors.officer3DateOfBirth)
                }
                helperText={
                  formik.touched.officer3DateOfBirth &&
                  formik.errors.officer3DateOfBirth
                }
              />
              <TextField
                fullWidth
                id="officer3Title"
                name="officer3Title"
                label="Title"
                value={formik.values.officer3Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer3Title &&
                  Boolean(formik.errors.officer3Title)
                }
                helperText={
                  formik.touched.officer3Title &&
                  formik.errors.officer3Title
                }
              />
              <TextField
                fullWidth
                id="officer3Email"
                name="officer3Email"
                label="Email Address"
                value={formik.values.officer3Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer3Email &&
                  Boolean(formik.errors.officer3Email)
                }
                helperText={
                  formik.touched.officer3Email &&
                  formik.errors.officer3Email
                }
              />
              <TextField
                fullWidth
                id="officer3Tel"
                name="officer3Tel"
                label="Phone Number"
                value={formik.values.officer3Tel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer3Tel &&
                  Boolean(formik.errors.officer3Tel)
                }
                helperText={
                  formik.touched.officer3Tel &&
                  formik.errors.officer3Tel
                }
              />
              <TextField
                fullWidth
                id="officer3Ownership"
                name="officer3Ownership"
                label="Ownership Percentage (%)"
                value={formik.values.officer3Ownership}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer3Ownership &&
                  Boolean(formik.errors.officer3Ownership)
                }
                helperText={
                  formik.touched.officer3Ownership &&
                  formik.errors.officer3Ownership
                }
              />
              <Box textAlign={"center"} mt={"2em"}>
                <h5 style={{ color: "#fff" }}>Officer 4</h5>
              </Box>
              <TextField
                fullWidth
                id="officer4FullName"
                name="officer4FullName"
                label="Full Name"
                value={formik.values.officer4FullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer4FullName &&
                  Boolean(formik.errors.officer4FullName)
                }
                helperText={
                  formik.touched.officer4FullName &&
                  formik.errors.officer4FullName
                }
              />
              <TextField
                fullWidth
                id="officer4SocialSecurityNumber"
                name="officer4SocialSecurityNumber"
                label="SSN"
                value={formik.values.officer4SocialSecurityNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer4SocialSecurityNumber &&
                  Boolean(formik.errors.officer4SocialSecurityNumber)
                }
                helperText={
                  formik.touched.officer4SocialSecurityNumber &&
                  formik.errors.officer4SocialSecurityNumber
                }
              />

              <TextField
                fullWidth
                id="officer4DateOfBirth"
                name="officer4DateOfBirth"
                label="Date of Birth"
                value={formik.values.officer4DateOfBirth}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer4DateOfBirth &&
                  Boolean(formik.errors.officer4DateOfBirth)
                }
                helperText={
                  formik.touched.officer4DateOfBirth &&
                  formik.errors.officer4DateOfBirth
                }
              />
              <TextField
                fullWidth
                id="officer4Title"
                name="officer4Title"
                label="Title"
                value={formik.values.officer4Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer4Title &&
                  Boolean(formik.errors.officer4Title)
                }
                helperText={
                  formik.touched.officer4Title &&
                  formik.errors.officer4Title
                }
              />
              <TextField
                fullWidth
                id="officer4Email"
                name="officer4Email"
                label="Email Address"
                value={formik.values.officer4Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer4Email &&
                  Boolean(formik.errors.officer4Email)
                }
                helperText={
                  formik.touched.officer4Email &&
                  formik.errors.officer4Email
                }
              />
              <TextField
                fullWidth
                id="officer4Tel"
                name="officer4Tel"
                label="Phone Number"
                value={formik.values.officer4Tel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer4Tel &&
                  Boolean(formik.errors.officer4Tel)
                }
                helperText={
                  formik.touched.officer4Tel &&
                  formik.errors.officer4Tel
                }
              />
              <TextField
                fullWidth
                id="officer4Ownership"
                name="officer4Ownership"
                label="Ownership Percentage (%)"
                value={formik.values.officer4Ownership}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.officer4Ownership &&
                  Boolean(formik.errors.officer4Ownership)
                }
                helperText={
                  formik.touched.officer4Ownership &&
                  formik.errors.officer4Ownership
                }
              />

            <Box mt={"2em"}>
              <Button
                mt={"2em"}
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </ThemeProvider>
        </StyledEngineProvider>
      </form>
    </Box>
  );
}

{
  /* <Grid container spacing={2} p={'2em'}>
            <Grid item xs={4}>

            </Grid>
            <Grid item xs={4}>
                
                    Taxpayer
                
            </Grid>
            <Grid item xs={4}>
                    <p color={'#fff'} style={{color: '#fff'}} >Spouse</p>
            </Grid>

            <Grid item xs={4}>
                
                    First Name & Middle Initial
                
            </Grid>
            <Grid item xs={4}>
                    <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    autoComplete='on'
                    />
            </Grid>
            <Grid item xs={4}>
                    <TextField
                        fullWidth
                        id="firstNameSpouse"
                        name="firstNameSpouse"
                        label="Spouse's First Name"
                        value={formik.values.firstNameSpouse}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstNameSpouse && Boolean(formik.errors.firstNameSpouse)}
                        helperText={formik.touched.firstNameSpouse && formik.errors.firstNameSpouse}
                        autoComplete='on'
                        />
            </Grid>

            <Grid item xs={4}>
                
            <p color={'#fff'} style={{color: '#fff'}} >Middle Name</p>
                
            </Grid>
            <Grid item xs={4}>
                
                    <TextField
                        fullWidth
                        id="additional-name"
                        name="additional-name"
                        label="Middle Name"
                        value={formik.values.additionalName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.additionalName && Boolean(formik.errors.additionalName)}
                        helperText={formik.touched.additionalName && formik.errors.additionalName}
                        autoComplete='on'
                        />
                
            </Grid>
            <Grid item xs={4}>
                 
                    <TextField
                        fullWidth
                        id="additional-nameSpouse"
                        name="additional-nameSpouse"
                        label="Spouse's Middle Name"
                        value={formik.values.additionalNameSpouse}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.additionalNameSpouse && Boolean(formik.errors.additionalNameSpouse)}
                        helperText={formik.touched.additionalNameSpouse && formik.errors.additionalNameSpouse}
                    />
                
            </Grid>

            <Grid item xs={4}>
                
            <p color={'#fff'} style={{color: '#fff'}} >Last Name</p>
                
            </Grid>
            <Grid item xs={4}>
                
                    <TextField
                        fullWidth
                        id="family-name"
                        name="family-name"
                        label="Last Name"
                        value={formik.values.familyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.familyName && Boolean(formik.errors.familyName)}
                        helperText={formik.touched.familyName && formik.errors.familyName}
                    />
                
            </Grid>
            <Grid item xs={4}>
                
                    <TextField
                        fullWidth
                        id="family-nameSpouse"
                        name="family-nameSpouse"
                        label="Last Name"
                        value={formik.values.familyNameSpouse}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.familyNameSpouse && Boolean(formik.errors.familyNameSpouse)}
                        helperText={formik.touched.familyNameSpouse && formik.errors.familyNameSpouse}
                    />
                
            </Grid>
        </Grid>
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
        </ThemeProvider> */
}
